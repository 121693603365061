<template>
	<v-card>
		<v-card-title class="justify-space-between mb-0 py-7">
			<span class="text-h5"><strong> User Roles</strong></span>
		</v-card-title>

		<v-row class="ml-2 mr-2 mb-3">
			<v-col cols="12" md="4" sm="6">
				<v-text-field v-model="addNewRole.role" label="Role name" outlined dense hide-details></v-text-field>
				<v-text class="m-0" color="primary">{{ error }}</v-text>
			</v-col>
			<v-col cols="12" md="6" sm="6" class="pl-0" >
				<v-btn color="primary" :disabled="true" class="mr-2" @click="addRole()"> Add Role</v-btn>
				<v-btn color="primary" @click="getroleList()" class="px-0">Reset</v-btn>
			</v-col>
		</v-row>

		<v-data-table fixed-header height="400" :headers="headers" :items="roleList" :loading="listLoading"
			:server-items-length="totallist" :items-per-page="10"
			:footer-props="{ 'items-per-page-options': [10, 20, 30] }" class="table-rounded pb-3" hide-default-footer
			disable-sort>
			<template #[`item.operate`]="{ item }">
				<v-btn color="primary" class="mr-3" @click="editUserRoleName(item)"> Edit </v-btn>
				<EditUserRole :visible="editUserRole" :item="editItem" @close="editUserRole = false" />


				<v-btn color="primary" text class="mr-3" @click="PermissionRole(item)"> Permission </v-btn>
				<Permission :visible="editUserRole1" :permissions="Permissionitem" :item="editItem1"
					@close="editUserRole1 = false" />


				<v-btn color="error" text @click="roleDeletePop(item)" :disabled="item.role_in_use ? true : false">
					Delete </v-btn>

			</template>
		</v-data-table>

		<v-dialog v-model="roleDeleteModal" max-width="550">
			<v-card>
				<v-card-title class="text-h5"> Delete confirmation </v-card-title>
				<v-card-text> Are you sure you want to delete ? </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="roleDeleteModal = false"> Cancel </v-btn>
					<v-btn color="primary" @click="deleteRoleName(thisItemId)"> Confirm </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbar" :timeout="timeout">
			{{ snackbarText }}

			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
			</template>
		</v-snackbar>

	</v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import roleService from '@/service/role.service'
import axios from 'axios'
import EditUserRole from './EditUserRole.vue'
import Permission from './Permission.vue'
const BaseUrl = "https://api.ebsr.in/users"
const localData = 'ekrjkej'
const token = localData.token

export default {
	components: {
		EditUserRole,
		Permission
	},
	// props: ['visible'],
	data() {

		return {
			// editRoleModal: false,
			roleDeleteModal: false,
			thisItemId: '',
			listLoading: false,
			totallist: 0,
			snackbar: false,
			snackbarText: '',
			editItem: '',
			editUserRole: false,
			editItem1: '',
			Permissionitem: '',
			editUserRole1: false,
			timeout: 2000,
			// usedRole: false,
			error: '',
			headers: [
				{ text: 'ROLE NAME', value: 'role' },
				{ text: 'ROLE ID', value: 'id', align: ' d-none' },
				{ text: 'OPERATE', value: 'operate', sortable: false },
			],
			roleList: [],
			addNewRole: {
				role: '',
			},
			// icons
			icons: {
				mdiSquareEditOutline,
				mdiDotsVertical,
			},

		}
	},

	watch: {
		options: {
			handler() {
				this.getroleList()
				// this.getpermission()
			},
			deep: true,
		},
	},

	created() {
		this.getroleList()
		// this.getpermission()
	},

	methods: {


		editUserRoleName(e) {
			this.editUserRole = true
			this.editItem = e
		},
		async PermissionRole(e) {
			this.editUserRole1 = true
			this.editItem1 = e
			const role_id = e.id
			const service = new roleService()
			let response = await service.getrole(role_id)
			this.Permissionitem = (response.data)
			console.log("helooo", this.Permissionitem)
		},

		roleDeletePop(e) {
			this.roleDeleteModal = true
			this.thisItemId = e.id
			// console.log("items", e)
		},


		async getroleList() {
			this.listLoading = true
			const service = new roleService()
			let response = await service.roleList()
			if (response) {
				this.roleList = response.data
				this.totallist = this.roleList.length
				this.addNewRole.role = ''
			} else {
				this.roleList = []
				this.totallist = 0
			}
			this.listLoading = false
		},
		async addRole() {
			if (!this.addNewRole.role) {
				console.log("enter role")
				this.snackbarText = "Please Enter Role Name First";
				this.snackbar = true
			}
			const data = {
				role: this.addNewRole.role,
			}
			try {
				const response = await axios.post(`${BaseUrl}/add_role`, data, {
					headers: {
						'Content-type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				})
				if (response.data.status == 200) {
					this.snackbarText = response.data.message
					this.addNewRole.role = "";
					this.getroleList()
				} else {
					this.snackbarText = response.data.message
					this.addNewRole.role = "";
				}
				this.snackbar = true
			} catch (e) {
				console.log(e)
			}
		},

		async deleteRoleName(item) {
			// console.log("dfrt",item)
			const data = {
				role_id: item,
			}
			try {
				const response = await axios.post(`${BaseUrl}/delete_role`, data, {
					headers: {
						'Content-type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				})
				// console.log("response",response)
				if (response.data.status == 200) {
					this.snackbarText = response.data.message
					this.getroleList()
					this.roleDeleteModal = false
				} else {
					this.snackbarText = response.data.message
				}
				this.snackbar = true
			} catch (e) {
				console.log(e)
			}
		},
	},
}
</script>
